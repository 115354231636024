@import '../variables';
@import '../new/variables-new';

.btn,
button.btn {
  white-space: nowrap;
  color: white;
  // Project button, to remove with project select bar
  &:hover,
  &:focus {
    background-color: $btn-hover-bg;
  }
  &:focus-visible {
    box-shadow: none;
  }

  &.btn:active {
    background-color: $btn-active-bg;
  }

  &-link {
    color: $primary;
    &:hover,
    &:focus {
      background: transparent;
    }
    &.btn:active {
      color: $link-color;
      background: transparent;
    }
  }

  &-danger {
    color: white;
    &:hover,
    &:focus {
      color: white;
      background-color: $btn-danger-hover;
    }
    &.btn:active {
      color: white;
      background-color: $btn-danger-active;
    }
  }

  &--outline {
    background: white;
    border: 1px solid $primary !important;
    color: $primary;
    line-height: calc($btn-line-height - 2px);
    &.btn-sm {
      line-height: calc($btn-line-height-sm - 2px);
    }
    &.btn-xsm {
      line-height: calc($btn-line-height-xsm - 2px);
    }
    &.btn-lg {
      line-height: calc($btn-line-height-lg - 2px);
    }
    &:disabled {
      color: $primary;
    }
    &:hover,
    &:focus {
      background-color: $btn-outline-hover-bg;
      color: $primary;
    }
    &.btn:active {
      background-color: $btn-outline-active-bg;
      color: $primary;
    }

    &-danger {
      border-color: $alert-danger-border-color !important;
      color: $danger !important;
      &:hover,
      &:focus {
        background-color: $danger-alfa-8;
      }
      &.btn:active {
        background-color: $danger-alfa-16;
      }
    }
  }

  &-secondary {
    color: $text-icon-dark;
    background-color: $btn-secondary-bg;

    &:hover,
    &:focus {
      background-color: $btn-secondary-hover-bg;
      color: $text-icon-dark;
    }

    &.btn:active {
      background-color: $btn-secondary-active-bg;
      color: $text-icon-dark;
    }
  }
  &-success {
    background-color: $success;
    &:hover,
    &:focus {
      background-color: $success400;
      color: $text-icon-light;
    }

    &.btn:active {
      background-color: $success600;
      color: $text-icon-light;
    }
  }

  &--transparent {
    background-color: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 38px;
    width: 38px;
    padding: 19px 0 18px 0;
    line-height: inherit;
    a {
      line-height: 1;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      box-shadow: none;
    }
  }

  &--remove:hover {
    svg path {
      fill: #d93939;
    }
  }
  &-with-icon {
    padding: 0 14px;
    background-color: $basic-alpha-8;
    border-radius: $border-radius;
    &:hover,
    &:active,
    &:disabled {
      background-color: $basic-alpha-8 !important;
      svg {
        path {
          fill: $primary;
        }
      }
    }
    &:focus {
      background-color: $basic-alpha-8 !important;
      svg {
        path {
          fill: $text-icon-dark;
        }
      }
    }
    &.btn-sm {
      padding: 0 10px;
    }
    &.btn-lg {
      line-height: $btn-line-height-lg;
    }
    &.btn-remove {
      background-color: $danger-alfa-8;
      &:hover,
      &:active,
      &:disabled,
      &:focus {
        background-color: $danger-alfa-8 !important;
        svg {
          path {
            fill: $danger;
          }
        }
      }
    }
  }
  &--with-icon {
    background: none !important;
    &.primary {
      color: $bt-brand-primary-dark-desaturated-lighter;
      path {
        fill: $bt-brand-primary-dark-desaturated-lighter;
      }
    }
    box-shadow: none;
    height: auto;
    line-height: 1;
    padding: 0 5px;

    &:hover,
    &:focus {
      background: none !important;
      color: darken($bt-brand-secondary, 10) !important;
      path {
        fill: darken($bt-brand-secondary, 10) !important;
      }
      box-shadow: none !important;
    }
  }

  &__icon {
    pointer-events: none;
    height: 25px;
    width: auto;
    margin-left: 5px;

    &--small {
      position: relative;
      height: 20px;
    }
  }
  &-project {
    background-color: $bg-light200;
    color: $text-icon-dark;
    padding: 0px 8px;
    width: 100%;
    height: 76px;
    border-radius: $border-radius-lg;
    &.btn-project-create {
      background-color: transparent;
      border: 1px dashed $basic-alpha-24;
      &:hover {
        background-color: transparent;
        & .btn-project-icon {
          background-color: $primary-alfa-8;
          path {
            fill: $primary;
          }
        }
      }
      .btn-project-icon {
        background-color: $bg-light300;
        padding: 6px 14px;
        margin-right: 12px;
        border-radius: $border-radius;
      }
    }
    &.btn:hover,
    &.btn:active,
    &.btn:focus {
      background-color: $bg-light200;
      color: $text-icon-dark;
      & .btn-project-letter {
        background-color: $primary;
      }
    }
    &-letter {
      min-width: 60px;
      height: 60px;
      background-color: $primary400;
      border-radius: $border-radius;
      color: $text-icon-light;
      text-transform: uppercase;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-title {
      white-space: normal;
      line-height: $line-height-base;
      text-align: left;
    }
  }
  &-lg {
    line-height: $btn-line-height-lg;
  }

  &-sm {
    line-height: $btn-line-height-sm;
  }
  &-xsm {
    padding: 0 12px;
    line-height: $btn-line-height-xsm;
    font-size: $font-caption-sm;
    border-radius: $border-radius-sm;
  }
}

.btn-link {
  text-decoration: none !important;
  vertical-align: baseline;
  font-family: $font-family;
  background: transparent;
  color: $link-color;
  @include transition(all 200ms);
  border: none;
  box-shadow: none;
  padding: 0;
  line-height: $line-height-base;
  text-transform: inherit;
  font-weight: 500;
  &:hover,
  &:focus {
    color: $link-color;
    background: transparent;
    text-decoration: underline;
    border: none;
    box-shadow: none;
  }
  &:active {
    background: transparent;
  }
  &.dark-link {
    color: $text-icon-dark;
    font-weight: bold;
  }
  &.btn-with-icon {
    padding: 0 14px;
    background-color: $basic-alpha-8;
    border-radius: $border-radius;
    line-height: $btn-line-height;
  }
}

$remove-btn-size: 24px;
$add-btn-size: 34px;
.btn-radio {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid $basic-alpha-24;
  border-radius: 50%;
  &:hover {
    border-color: $primary;
  }

  &.btn-radio-on {
    border-color: $primary;
    display: flex;
    justify-content: center;
    &::before {
      content: '';
      display: block;
      border-radius: 50%;
      background-color: $primary;
      width: 12px;
      height: 12px;
      align-self: center;
    }
    &:hover {
      border-color: $primary600;
      &::before {
        background-color: $primary600;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .btn {
    &__md-full {
      width: 100%;
    }
  }
}

.dark {
  .btn {
    &:hover,
    &:focus {
      background-color: $btn-hover-bg-dark;
    }
    &:active {
      background-color: $primary;
    }

    &.btn-secondary {
      color: white;
      background-color: $btn-secondary-bg-dark;
      &:hover,
      &:focus {
        background-color: $btn-secondary-hover-bg-dark;
      }
      &:active {
        color: white;
        background-color: $btn-secondary-active-bg-dark;
      }
    }
    &.btn-success {
      &:hover,
      &:focus {
        background-color: $success400;
        color: $text-icon-light;
      }
    }

    &--outline {
      background: transparent;
      border-color: $primary400;
      color: $primary400;
      &:disabled {
        color: $primary400;
      }
      &:hover {
        color: $primary400;
        border-color: $primary400;
        background-color: $btn-outline-hover-bg-dark;
      }
      &:focus {
        background-color: $btn-outline-focus-bg-dark;
      }

      &-danger {
        border-color: $danger400 !important;
        color: $danger400 !important;
      }
    }
    &--with-icon {
      background: none !important;

      &.primary {
        color: $dark-highlight-color;

        path {
          fill: $dark-highlight-color;
        }
      }
      &:hover,
      &:focus {
        background: none !important;
        color: $dark-highlight3 !important;
        path {
          fill: $dark-highlight3 !important;
        }
      }
    }
    &.btn-link {
      background-color: transparent;
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        color: $dark-highlight-color;
      }
    }
    .btn-radio {
      border-color: $white-alpha-24;
    }

    &-project {
      background-color: $bg-dark200;
      color: $text-icon-light;
      &.btn-project-create {
        &:hover {
          background-color: transparent;
          & .btn-project-icon {
            background-color: $primary-alfa-8;
            path {
              fill: $primary;
            }
          }
        }
        & .btn-project-icon {
          background-color: $basic-alpha-8;
        }
      }

      &.btn:hover,
      &.btn:focus,&.btn:active {
        background-color: $bg-dark200;
        color: $text-icon-light;
        & .btn-project-letter {
          background-color: $primary;
        }
      }
    }
    &.btn-with-icon {
      background-color: $basic-alpha-8;
      &.btn-remove {
        background-color: $danger-alfa-8;
      }
    }
  }

  .btn-link {
    color: $primary;
  }
}
